<!-- src/App.vue -->
<template>
  <MapComponent />
</template>

<script>
import MapComponent from './components/MapComponent.vue';

export default {
  components: {
    MapComponent
  }
};
</script>
